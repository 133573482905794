var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-width-container",
    [
      _c("gov-back-link", { attrs: { to: { name: "organisations-index" } } }, [
        _vm._v("Back to organisations")
      ]),
      _c(
        "gov-main-wrapper",
        [
          _vm.loading
            ? _c("ck-loader")
            : _c(
                "gov-grid-row",
                [
                  _c("vue-headful", {
                    attrs: {
                      title:
                        _vm.appName +
                        " - Organisation: " +
                        _vm.organisation.name
                    }
                  }),
                  _c(
                    "gov-grid-column",
                    { attrs: { width: "two-thirds" } },
                    [
                      _c("gov-heading", { attrs: { size: "m" } }, [
                        _vm._v("View organisation")
                      ]),
                      _vm.updated
                        ? _c("gov-inset-text", [
                            _vm._v(
                              "Organisation " +
                                _vm._s(_vm.organisation.name) +
                                " has been\n          updated"
                            )
                          ])
                        : _vm._e(),
                      _c("ck-organisation-details", {
                        attrs: { organisation: _vm.organisation }
                      }),
                      _vm.auth.isSuperAdmin
                        ? [
                            _c("gov-body", [
                              _vm._v(
                                "Please be certain of the action before deleting an\n            organisation"
                              )
                            ]),
                            _c("gov-section-break", { attrs: { size: "l" } }),
                            _c("ck-delete-button", {
                              attrs: {
                                resource: "organisation",
                                endpoint:
                                  "/organisations/" + this.organisation.id
                              },
                              on: { deleted: _vm.onDelete }
                            })
                          ]
                        : _vm._e()
                    ],
                    2
                  ),
                  _vm.auth.canEdit("organisation", _vm.organisation)
                    ? _c(
                        "gov-grid-column",
                        {
                          staticClass: "text-right",
                          attrs: { width: "one-third" }
                        },
                        [
                          _c(
                            "gov-button",
                            {
                              attrs: {
                                to: {
                                  name: "organisations-edit",
                                  params: { organisation: _vm.organisation.id }
                                }
                              }
                            },
                            [_vm._v("\n          Edit organisation\n        ")]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }